import React from 'react';
import JuliaDounyGuide from '../assets/julia-douny-guyot-guide-verdun.webp';
import saviezvous from '../assets/saviezvous.webp';
import cochon from '../assets/cochon.webp';

function Infos() {
  return (
    <section id="infos" className="mb-8">

<div className="container-fluid px-5 py-16 mx-auto bg-primary-500 text-white">
    <div className="xl:w-2/3 lg:w-3/4 w-full mx-auto text-justify">
      <img className="mb-4 object-cover object-center" alt="Saviez-vousque l’Histoire dépendait… d’un cochon?" src={saviezvous} />

      <p className="mb-4 leading-relaxed text-justify">Les balades contées permettent de découvrir La Rochelle de manière ludique et immersive. Le Vieux Port, souvent considéré comme le plus beau quartier avec ses vues pittoresques et son ambiance animée, devient le théâtre de récits où réalité et imaginaire se rencontrent.</p>
      <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
      <img className="mt-2 object-cover object-center" alt="Cochon de La Rochelle" src={cochon} />
    </div>
    <p className="mb-4 leading-relaxed text-justify">
      Pour vivre cette expérience de visite insolite, réservez votre place et rejoignez-nous à la Place de Verdun pour une balade de 1h45. 
      Laissez-vous emporter par l'histoire de La Rochelle, racontée avec passion, originalité et émotion.</p>
    </div>
  </div>

  < div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
    < div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
      <img className="object-cover object-center rounded" alt="Julia Douny Guide Verdun La Rochelle" src={JuliaDounyGuide} />
    </div>
    <div className="xs:text-center sm:text-center md:text-justify lg:text-justify px-9">

      <h2 className="text-2xl font-semibold mb-4">Informations Pratiques</h2>

      <p className="mb-4 leading-relaxed">
      <strong>Réservations de groupe :</strong> <br/>Possible (min. 10 personnes) pendant et hors vacances scolaires
      </p>

      <p className="mb-4 leading-relaxed">
      <strong>Public :</strong> Tout public à partir de 8 ans
      </p>
      <p className="mb-4 leading-relaxed">
      <strong>Plein tarif :</strong> 15 € | <strong>Tarif réduit :</strong> 10 €
      </p>
      <p className="mb-4 leading-relaxed">
      <strong>Durée :</strong> 1h45
      </p>
      <p className="mb-4 leading-relaxed">
      <strong>Dates :</strong> Tous les 1ers samedis du mois toute l’année & tout l’été
      </p>

      <p className="mb-4 leading-relaxed">
      <strong>Infos et réservations :</strong> 06 16 56 06 85
      </p>
      <p className="mb-4 leading-relaxed">
        <a target="_blank" href="https://www.helloasso.com/associations/compagnie-o-tom-po-tom" className="inline-flex text-white bg-primary-500 border-0 py-2 px-6 focus:outline-none hover:bg-primary-900 rounded text-lg">Réserver ma place Hello Asso au moins 48 H à l'avance</a>
      </p>

    </div>
  </div>





    </section>
  );
}

export default Infos;
