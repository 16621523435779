import React from 'react';

const DATES = () => {
  const sousTitre = "Été 2024 - Départ à 10h00 "
  const datesBalades = [
      { date: "Mercredi 10 juillet [COMPLET] ", className : " text-primary-200 line-through", url : "#"},
      { date: "Mercredi 19 juillet [COMPLET - Centre Socio-Culturel de Tasdon-Bongraine - La Rochelle ] ", className : " text-primary-200 line-through", url : "#"},
      { date: "Mercredi 17 juillet", className : " text-primary-200 line-through", url : "#"},
      { date: "Mercredi 24 juillet", className : " text-primary-200 line-through", url : "#"},
      { date: "Lundi 22 juillet", className : " text-primary-200 line-through", url : "#"},
      { date: "Lundi 05 août", className : " text-primary-200 line-through", url : "#"},
      { date: "Mercredi 07 août [COMPLET] ", className : " text-primary-200 line-through", url : "#"},
      { date: "Mercredi 14 août", className : " text-secondary-500", url : "https://www.helloasso.com/associations/compagnie-o-tom-po-tom/evenements/balade-contee-14-aout-2024"},
      { date: "Lundi 19 août", className : " text-secondary-500", url : "https://www.helloasso.com/associations/compagnie-o-tom-po-tom/evenements/balade-contee-19-aout-2024"},
      { date: "Mercredi 21 août [COMPLET] ", className : " text-primary-200 line-through", url : "#"},
      { date: "Mercredi 28 août", className : " text-secondary-500", url : "https://www.helloasso.com/associations/compagnie-o-tom-po-tom/evenements/balade-contee-28-aout-2024"},
  ];

  return (
    <section className="bg-white">
      <div className="px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
      <h2 className="text-xl font-medium title-font mb-4 text-gray-900">Prochaines balades contées {sousTitre}</h2>
        <div className="grid xs:text-center sm:text-center md:text-justify lg:text-justify text-left border-t border-gray-200 dark:border-gray-700 pt-4 md:grid-cols-4">
          {datesBalades.map((qa, index) => (
            <div key={index} className={qa.className}>
              <h3 className="flex items-center mb-4 text-lg font-medium">
                <svg className="flex-shrink-0 mr-2 w-5 h-5 text-primary-500 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path>
                </svg>
                <a href={qa.url}>{qa.date}</a>
              </h3>

            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default DATES;
