import React from 'react';
// images
import { LazyLoadImage } from "react-lazy-load-image-component";
import Logo from '../assets/logo-2023-espace-ok.png'; 

const Footer = () => {
  return (
    <footer className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-wrap md:text-left text-center order-first">
        <div className="lg:w-1/2 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
              
            <a href="https://otompotom.fr" className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
              <LazyLoadImage src={Logo}
              width={222} height={222}
              alt="Image Logo O Tom Po Tom"
            />
            </a>

            </h2>
            <p className="leading-relaxed text-base text-gray-700 text-md">
              <strong>Compagnie de Spectacle vivant</strong><br />
              Imaginer ensemble | Transmettre | Questionner | Traverser | Oser | Entremêler | Donner la parole | Apprendre | Prendre le temps.<br /><br />
            </p>
        </div>

          <div className="lg:w-1/4 md:w-1/4 w-full px-4">
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">Liens Importants</h2>
            <nav className="list-none mb-10">
              <li>
                <a href="https://otompotom.fr" className="text-gray-600 hover:text-gray-800">Compagnie O Tom Po Tom</a>
              </li>
              <li>
                <a href="https://otompotom.fr/takatoke" className="text-gray-600 hover:text-gray-800">Takatoké</a>
              </li>
              <li>
                <a href="https://otompotom.fr/contact" className="text-gray-600 hover:text-gray-800">Contact</a>
              </li>
              <li>
                <a href="https://otompotom.fr/balades-contees" className="text-gray-600 hover:text-gray-800">Balades Contées</a>
              </li>
              <li>
                <a href="https://otompotom.fr/espace-pro" className="text-gray-600 hover:text-gray-800">Espace Pro</a>
              </li>
              <li>
                <a href="https://otompotom.fr/qui-sommes-nous" className="text-gray-600 hover:text-gray-800">Qui sommes-nous</a>
              </li>
              <li>
                <a href="https://otompotom.fr/contes-a-emporter" className="text-gray-600 hover:text-gray-800">Contes à Emporter</a>
              </li>
              <li>
                <a href="https://otompotom.fr/un-tantinet-givre" className="text-gray-600 hover:text-gray-800">Un Tantinet Givré</a>
              </li>
              <li>
                <a href="https://otompotom.fr/reinventons-la-mediatheque" className="text-gray-600 hover:text-gray-800">Réinventons la Médiathèque</a>
              </li>
              <li>
                <a href="https://otompotom.fr/glossary/julia-douny" className="text-gray-600 hover:text-gray-800">Julia Douny</a>
              </li>
              <li>
                <a href="https://otompotom.fr/les-projets-avec-vous" className="text-gray-600 hover:text-gray-800">Les projets avec VOUS</a>
              </li>
            </nav>
          </div>

              <div className="lg:w-1/4 md:w-1/4 w-full px-4">
              <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">Siège social</h2>
              <p className="leading-relaxed text-base">
              Compagnie O Tom Po Tom<br />
              Mairie de proximité de Mireuil<br />
              2 Square de la Passerelle<br />
              17000 La Rochelle<br />
              Association Loi 1901<br />
              SIRET 832 367 619 00034<br />
              Licence 2 : L-R-20-008697<br />
              Licence 3 : L-R-20-008735
            </p>
          </div>
        </div>
      </div>
      <div className="bg-gray-100">
        <div className="container px-5 py-6 mx-auto flex items-center sm:flex-row flex-col">
          <p className="text-sm text-gray-500 sm:ml-6 sm:mt-0 mt-4">© 2024 Compagnie O Tom Po Tom</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
