import React from 'react';
import juliaDounyGuyot from '../assets/julia-douny-guyot.webp'
function References() {
  return (

    <section id="references" className="text-white body-font bg-primary-700">
  <div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
    <img className="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded-full ring-2 ring-primary-100 shadow-lg" alt="Artiste passionnée d’Histoire, Julia Douny Guyot " src={juliaDounyGuyot} />
    <div className="text-center lg:w-2/3 w-full">
      <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium">Julia Douny Guyot</h1>
      <h2 className="title-font sm:text-xl text-3xl mb-4 font-medium">Conteuse & passionnée d’Histoire</h2>

      <p className="mt-2 text-center ">Née dans les Tours de La Rochelle – Centre des Monuments Nationaux avec qui Julia a travaillé, cette balade se décline désormais en déambulation dans les rues de La Rochelle. En 2022, elle a fait partie des Journées du Patrimoine et a rejoint le dispositif du PASS CULTURE (individuel et collectif).</p>
      <p className="mt-2 text-center ">Comédienne, conteuse et metteuse en scène, vous pouvez découvrir le travail de Julia Douny Guyot sur la page "Qui sommes-nous" du site de la compagnie <a href="https://otompotom.fr/qui-sommes-nous/">O Tom Po Tom</a> à La Rochelle.
      
      </p>
      <div className="flex flex-wrap  justify-center mt-2">
      <a className="inline-flex text-white bg-primary-200 border-0 py-2 px-6 m-2 focus:outline-none hover:bg-primary-900 rounded text-lg" href="https://otompotom.fr/qui-sommes-nous/">Cie O Tom Po Tom</a>
      <a className="inline-flex text-white bg-secondary-500 border-0 py-2 px-6  m-2 focus:outline-none hover:bg-secondary-900 rounded text-lg" href="https://www.helloasso.com/associations/compagnie-o-tom-po-tom">Réserver une date ( au moins 48 H à l'avance )</a>
      </div>
    </div>
  </div>

   </section>
  );
}

export default References;
