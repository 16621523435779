import React from 'react';
// images
import { LazyLoadImage } from "react-lazy-load-image-component";
import Logo from '../assets/logo-2023-baseline-ok.png';
import Affiche from '../assets/affiche.webp';
function Introduction() {
  return (

<>


<section id="introduction" className="text-gray-600 body-font">
  <div className="container-fluid bg-gray-100 mx-auto flex px-24 py-12 md:flex-row flex-col items-center">
    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">

      <a href="https://www.balades-contees-la-rochelle.com/">
      <LazyLoadImage src={Affiche}
          alt="Affiche Balades Contées"
          className="object-cover object-center rounded shadow-lg"
         />
      </a>
      <p className="mt-8 font-medium text-primary">La Rochelle : CONTES & LÉGENDES, HISTOIRE & PATRIMOINE</p>

    </div>
    <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">

      <a href="https://otompotom.fr">
      <LazyLoadImage src={Logo}
          width={256} height={256}
          alt="Image Logo O Tom Po Tom"
          className="float-right"
         />
      </a>
      <h1 className="text-3xl text-primary-500 mt-4"> “La Rochelle - Entre Rêves et Réalité”</h1>


      <p className="mt-4 mb-4 leading-relaxed text-justify border-t pt-4">
      <span className="font-bold uppercase">Visite contée</span> « Quand l’Histoire est racontée par une artiste qui nous fait traverser 9 siècles avec un brin de malice et le petit grain de folie de la Compagnie O Tom Po Tom… Pour découvrir le centre historique de La Rochelle au gré d’anecdotes et de destins qui nourrissent toujours l’âme de cette ville.
      </p>
      <p className="mb-4 leading-relaxed text-justify">
      Partez pour une balade insolite au ton décalé, hors du temps, qui vous emmène découvrir le passé et les légendes de La Rochelle, ville palimpseste qui se dévoile au promeneur comme un mille-feuilles.
      </p>
      <p className="mb-4 leading-relaxed text-justify">
      Cette visite très documentée aborde l’Histoire par le prisme de faits et d’anecdotes historiques qui s’entrelacent avec des personnages et figures devenus légendaires. L’imaginaire se superpose à la réalité, et l’on rêve les yeux ouverts au milieu de la ville, là où l’artiste nous fait entrevoir ce qui n’est plus, ou qui aurait été…      
      </p>
      <p className="mb-4 leading-relaxed text-justify">
      La Rochelle, avec ses lieux pittoresques et son riche passé, se dévoile d'une manière unique à travers les balades contées "Entre Rêves et Réalité". Cette activité offre une nouvelle perspective sur la ville, mêlant histoires et légendes captivantes.
      </p>

      <h2 className="mt-4 text-primary-500 title-font tracking-wider text-xs">Par la Compagnie O Tom Po Tom - Compagnie de Spectacle vivant à La Rochelle depuis 2017 </h2>

    </div>
  </div>

</section>

</>
  );
}

export default Introduction;
