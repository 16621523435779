import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import Header from './components/Header';
import Dates from './components/Dates';
import Footer from './components/Footer';
import Faq from './components/Faq';
import Introduction from './components/Introduction';
import Infos from './components/Infos';
import References from './components/References';
import Testimonials from './components/Testimonials';
import SEO from './components/Seo';  // Assurez-vous que le chemin est correct pour votre projet

import {ShareSocial} from 'react-share-social' ;


function App() {
  return (
    <HelmetProvider>
      <div className="App">
        <Header />
        <SEO
          title="Balades Contées 'La Rochelle - Entre Rêves et Réalité'"
          description="Découvrez La Rochelle à travers des balades contées, mélangeant Histoire, contes et légendes. Idéal pour les familles, enfants et scolaires."
          keywords="balade contée, tourisme La Rochelle, visiter La Rochelle, guide famille, enfants, scolaires"
          url="https://balades-contees-la-rochelle.com"
          image="https://balades-contees-la-rochelle.com/balade-contee.jpg"
        />
        <main>
          <Introduction />
          <Dates />
          <Infos />

          <References />
          <Faq />
          <Testimonials />
        </main>
      </div>

c
      <Footer />
    </HelmetProvider>
  );
}

export default App;
