import React from 'react';

const FAQ = () => {
  const questionsAndAnswers = [
    {
      question: "Pourquoi Participer ?",
      answer: "Participer à une balade contée, c’est réserver une place pour l’une des sessions proposées et se laisser guider par une conteuse passionnée. L'histoire et les légendes de La Rochelle collectés sur neuf siècles "
    },
    {
      question: "Quand ont lieu les prochaines balades contées à La Rochelle ?",
      answer: "Les prochaines balades contées auront lieu en été 2024, les lundis et mercredis. Les dates spécifiques incluent : le 10, 17, 24 juillet ; le 22 juillet ; le 5, 7, 14, 19, 21, 28 août."
    },
    {
      question: "Comment puis-je réserver une balade contée ?",
      answer: "Vous pouvez réserver votre place en appelant le 06 16 56 06 85 ou en ligne via la billetterie Hello Asso au moins 48 H à l'avance ."
    },
    {
      question: "Quelle est la durée de la balade contée ?",
      answer: "La balade contée dure environ 1h45."
    },
    {
      question: "Quel est le point de départ de la balade ?",
      answer: "Le départ se fait à 10h00 depuis la Place de Verdun."
    },
    {
      question: "Qui est l'artiste derrière les balades contées ?",
      answer: "Julia Douny Guyot, une artiste passionnée d’Histoire, comédienne, conteuse et metteuse en scène, est à l'origine de cette visite."
    },
    {
      question: "À quel public s'adressent les balades contées ?",
      answer: "Les balades sont destinées à un public tout public à partir de 8 ans, y compris les scolaires du CE2 au lycée. Pour un événements, des vacances ou pour les passionnés d'Histoire et d'histoires."
    },
    {
      question: "Est-il possible de réserver une balade pour un groupe ?",
      answer: "Oui, la réservation de groupe est possible pour un minimum de 10 personnes, pendant et hors vacances scolaires."
    },
    {
      question: "Quelles sont les références de cette activité ?",
      answer: "Cette balade a été présentée lors des Journées du Patrimoine et est incluse dans le dispositif du PASS CULTURE. Elle est également visible sur KIDIKLIK 17."
    },
    {
      question: "Qu'ont pensé les participants des balades contées ?",
      answer: "Les participants ont trouvé la balade captivante, magique, et enrichissante, offrant une nouvelle perspective sur La Rochelle à travers des anecdotes et des légendes."
    },
    {
      question: "Où puis-je voir une vidéo sur les balades contées ?",
      answer: "Vous pouvez regarder un extrait du JT de France 3 de 2019, intitulé “Les tours en famille, des visites ludiques et des histoires incroyables”."
    },
    {
      question: "Quels sont les lieux potentiels de la visite ?",
      answer: "Place de Verdun, Cathédrale Saint-Louis, Rue Pernelle, Rue Aufrédy, Rue de l'Escale, Rue Verdière, La Coursive, Le Vieux Port La Tour de la Chaîne"
    },
    {
      question: "Qui est la compagnie O Tom Po Tom ?",
      answer: "Créée en 2017 à La Rochelle et implantée en quartier prioritaire, la Compagnie O Tom Po Tom se construit comme un espace de création, de transmission, d’accompagnement et de rencontres des artistes et des publics. "
    },
    {
      question: "Comment contacter la Compagnie pour plus d'informations ?",
      answer: "Vous pouvez contacter la compagnie par email à cie.otompotom@gmail.com ou par téléphone au 06 16 56 06 85. https://otompotom.fr"
    },
  ];

  return (
    <section className="bg-white">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
      <h2 className="text-2xl font-medium title-font mb-4 text-gray-900">Questions-réponses</h2>

        <div className="grid pt-8 text-left border-t border-gray-200 md:gap-16 dark:border-gray-700 md:grid-cols-2">
          {questionsAndAnswers.map((qa, index) => (
            <div key={index} className="mb-2">
              <h3 className="flex items-center mb-4 text-lg font-medium text-secondary-500 ">
                <svg className="flex-shrink-0 mr-2 w-5 h-5 text-primary-500 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path>
                </svg>
                {qa.question}
              </h3>
              <p className="text-primary-500 dark:text-primary-500">{qa.answer}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
