import React from 'react';
import CerclePhoto from '../assets/rond.webp';
// import LogoBaseline from '../assets/logo-2023-baseline-ok.png';
import bateau from '../assets/bateau-bande.webp';

function Header() {
  return (
    <header 
      className="relative bg-primary-900 text-white p-8 flex flex-col md:flex-row items-center md:items-center"
      style={{ backgroundImage: `url(${bateau})`, backgroundSize: 'cover', backgroundPosition: 'center', opacity: 1 }}
    >
      <div className="relative z-10 flex flex-col md:flex-row items-center md:items-center">
        <img className="h-64 md:mr-6" alt="Affiche Balades Contées" src={CerclePhoto} />
        <div className="text-center md:text-left mt-4 md:mt-0">
          <h1 className="text-3xl">Balades Contées “La Rochelle - Entre Rêves et Réalité”</h1>
          <p className="mt-2">Visite contée pour découvrir La Rochelle autrement ! Par Julia Douny Guyot - Compagnie O Tom Po Tom </p>
        </div>
      </div>
    </header>
  );
}

export default Header;
